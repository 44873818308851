
    import ContactCard from "@/components/contact-card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            ContactCard,
        },
    })

    // *****************************************************************************************************************
    export default class DistrictTermsAndConditions extends Vue {
        // Data function
        public data() {
            return {
                districtIconPathAndName:
                    require("@/assets/images/icon_book.png"),

                districtImage01PathAndName:
                    require("@/assets/images/district-01.png"),
                districtImage02PathAndName:
                    require("@/assets/images/district-02.png"),
                districtImage03PathAndName:
                    require("@/assets/images/district-03.png"),
                districtImage04PathAndName:
                    require("@/assets/images/district-04.png"),
                districtImage05PathAndName:
                    require("@/assets/images/district-05.png"),
                districtImage06PathAndName:
                    require("@/assets/images/district-06.png"),
                districtImage07PathAndName:
                    require("@/assets/images/district-07.png"),
                districtImage08PathAndName:
                    require("@/assets/images/district-08.png"),
                districtImage09PathAndName:
                    require("@/assets/images/district-09.png"),
                districtImage10PathAndName:
                    require("@/assets/images/district-10.png"),
                districtImage11PathAndName:
                    require("@/assets/images/district-11.png"),
                districtImage12PathAndName:
                    require("@/assets/images/district-12.png"),
                districtImage13PathAndName:
                    require("@/assets/images/district-13.png"),
                districtImage14PathAndName:
                    require("@/assets/images/district-14.png"),
                districtImage15PathAndName:
                    require("@/assets/images/district-15.png"),
                districtImage16PathAndName:
                    require("@/assets/images/district-16.png"),
                districtImage17PathAndName:
                    require("@/assets/images/district-17.png"),
                districtImage18PathAndName:
                    require("@/assets/images/district-18.png"),
                districtImage19PathAndName:
                    require("@/assets/images/district-19.png"),
                districtImage20PathAndName:
                    require("@/assets/images/district-20.png"),
            };
        }
    }
